<template>
  <div>
    <b-tabs
      pills
      fill
    >
      <b-tab active>
        <template #title>
          <feather-icon icon="UserIcon" />
          <span>Müşteri Bilgileri</span>
        </template>
        <customer-info />
        <related-persons />
        <activities />
        <social-media />
        <customer-invoice />
      </b-tab>
      <b-tab lazy>
        <template #title>
          <feather-icon icon="MapPinIcon" />
          <span>Adres Bilgileri</span>
        </template>
        <customer-address />
      </b-tab>
      <b-tab lazy>
        <template #title>
          <feather-icon icon="ClockIcon" />
          <span>Müşteri Geçmişi</span>
        </template>
        <customer-history :customer-id="dataItem.id" />
      </b-tab>
    </b-tabs>
  </div>
</template>
<script>
import {
  BTabs, BTab,
} from 'bootstrap-vue'
import CustomerInfo from '@/views/Admin/Customers/View/CustomerInfo.vue'
import RelatedPersons from '@/views/Admin/Customers/View/RelatedPersons.vue'
import Activities from '@/views/Admin/Customers/View/Activities.vue'
import SocialMedia from '@/views/Admin/Customers/View/SocialMedia.vue'
import CustomerInvoice from '@/views/Admin/Customers/View/CustomerInvoice.vue'
import CustomerAddress from '@/views/Admin/Customers/View/CustomerAddress.vue'
import CustomerHistory from '@/views/Admin/Customers/View/CustomerHistory.vue'

export default {
  name: 'View',
  components: {
    BTabs,
    BTab,
    CustomerInfo,
    RelatedPersons,
    Activities,
    SocialMedia,
    CustomerInvoice,
    CustomerAddress,
    CustomerHistory,
  },
  computed: {
    dataItem() {
      return this.$store.getters['customers/dataItem']
    },
  },
  created() {
    this.getData()
  },
  methods: {
    getData() {
      this.$store.dispatch('customers/getDataItem', this.$route.params.id)
    },
  },
}
</script>
